import { useAtom } from 'jotai'
import { offsiteShiftsAtom } from '../_atoms/OffsiteShifts'
import { useEffect } from 'react'

function useOffsiteShifts() {
  const [offsiteShifts, query] = useAtom(offsiteShiftsAtom)

  useEffect(() => query(), [query])

  return offsiteShifts
}

export default useOffsiteShifts
