import Typography from '@mui/material/Typography'
import format from 'date-fns/format'
import { BusinessWorkplaceAttendance } from 'gql/types'
import parseDate from 'helpers/parseDate'

interface Props {
  attendance: BusinessWorkplaceAttendance
}

function ClockOut({ attendance }: Props) {
  if (!attendance.clockOutOrigin)
    return (
      <Typography style={{ color: 'red', fontSize: 12 }}>下班未打卡</Typography>
    )

  const clockOut = attendance.clockOut || attendance.clockOutOrigin

  return (
    <Typography
      style={{
        color:
          attendance.leaveEarlyMins > 0 || attendance.overtimeMins > 0
            ? 'red'
            : 'grey',
        fontSize: 12,
      }}
    >
      {format(parseDate(clockOut), 'HH:mm')} 下班
    </Typography>
  )
}

export default ClockOut
