import * as Sentry from '@sentry/react'
import {
  cacheExchange,
  createClient,
  errorExchange,
  fetchExchange,
} from '@urql/core'
import { getEnv } from 'env'
import logout from 'helpers/logout'
import { atom } from 'jotai'
import { Client, ClientOptions } from 'urql'

const clientAtom = atom<Client | null>(null)

const clientOptions: ClientOptions = {
  url: getEnv().apiUrl,
  fetchOptions: () => {
    return {
      mode: 'cors',
      credentials: 'include',
    }
  },
  requestPolicy: 'cache-and-network',
  exchanges: [
    cacheExchange,
    errorExchange({
      onError: error => {
        const isUnauthorized = error.networkError?.message === 'Unauthorized'
        const isUnauthenticated = error.graphQLErrors.some(
          e => e.message === 'unauthenticated'
        )
        const isAuthError = isUnauthorized || isUnauthenticated

        const session = window.localStorage.getItem('session')
        if (isAuthError && session) {
          Sentry.setContext('error', { error })
          Sentry.setContext('networkError', error.networkError || {})
          Sentry.setContext('graphQLErrors', error.graphQLErrors)
          Sentry.setContext('session', { session })
          Sentry.setContext('isUnauthorized', { isUnauthorized })
          Sentry.setContext('isUnauthenticated', { isUnauthenticated })
          Sentry.captureMessage('gql session logout onError')
          logout()
        }
      },
    }),
    fetchExchange,
  ],
}

const setupClient = () => {
  return createClient(clientOptions)
}

export const gqlClientAtom = atom(
  get => get(clientAtom),
  (get, set, newClient?: Client | undefined) => {
    set(clientAtom, newClient ? newClient : setupClient())
  }
)

gqlClientAtom.onMount = set => {
  set(setupClient())
}
