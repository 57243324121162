import Typography from '@mui/material/Typography'
import format from 'date-fns/format'
import { BusinessWorkplaceAttendance } from 'gql/types'
import parseDate from 'helpers/parseDate'

interface Props {
  attendance: BusinessWorkplaceAttendance
}

function ClockIn({ attendance }: Props) {
  if (!attendance.clockInOrigin)
    return (
      <Typography style={{ color: 'red', fontSize: 12 }}>上班未打卡</Typography>
    )

  const clockIn = attendance.clockIn || attendance.clockInOrigin

  return (
    <Typography
      style={{
        color: attendance.lateMins > 0 ? 'red' : 'grey',
        fontSize: 12,
      }}
    >
      {format(parseDate(clockIn), 'HH:mm')} 上班
    </Typography>
  )
}

export default ClockIn
