import Box from '@mui/material/Box'
import { ReactComponent as Aibou } from 'assets/aibou.svg'
import { ReactComponent as Crew } from 'assets/crew.svg'
import isLowRes from 'helpers/isLowRes'

function AibouCrewIcon() {
  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <Aibou
        style={{
          width: isLowRes ? 56 : 70,
          height: isLowRes ? 24 : 30,
          marginLeft: 20,
          marginRight: isLowRes ? 6 : 8,
        }}
      />

      <Crew
        fill="white"
        style={{
          width: isLowRes ? 56 : 70,
          height: isLowRes ? 24 : 30,
          marginRight: 16,
        }}
      />
    </Box>
  )
}

export default AibouCrewIcon
