import { sessionAtom } from 'atoms/Session'
import { gqlClientAtom } from 'gql'
import { BusinessWorkplaceShift } from 'gql/types'
import { atom } from 'jotai'
import getToday from 'helpers/getToday'
import format from 'date-fns/format'
import { TimeClockPage_GetOffsiteShifts } from './_gql'

const queryResultAtom = atom<BusinessWorkplaceShift[] | null>(null)

export const offsiteShiftsAtom = atom(
  get => get(queryResultAtom),
  (get, set) => {
    const queryData = async () => {
      const today = getToday()
      const date = format(today, 'yyyy-MM-dd')
      const client = get(gqlClientAtom)
      const workplaceId = get(sessionAtom)?.workplaceId
      if (!client || !workplaceId) return
      const result = await client
        .query(TimeClockPage_GetOffsiteShifts, {
          workplaceId,
          startDate: date,
          endDate: date,
        })
        .toPromise()
      if (result.data) {
        set(queryResultAtom, parse(result))
      }
    }
    queryData()
  }
)

const parse = (result: any): BusinessWorkplaceShift[] | null =>
  result?.data?.node?.offsiteShifts
