import { sessionAtom } from 'atoms/Session'
import { gqlClientAtom } from 'gql'
import { UserSession } from 'gql/types'
import { atom } from 'jotai'
import { Sessions } from './_gql'

const queryResultAtom = atom<UserSession[] | null>(null)

export const userSessionsAtom = atom(
  get => get(queryResultAtom),
  (get, set) => {
    const queryData = async () => {
      const client = get(gqlClientAtom)
      const businessId = get(sessionAtom)?.businessId
      if (!client || !businessId) return
      const result = await client.query(Sessions, {}).toPromise()
      if (result.data) {
        set(queryResultAtom, parse(result))
      }
    }
    queryData()
  }
)

const parse = (result: any): UserSession[] | null =>
  result?.data?.currentUser?.sessions
